import React, { useContext } from "react";
import { Helmet } from 'react-helmet';
import { StyledContent as Content } from "../components/content/Content";
import { StyledParagraph as Paragraph } from "../components/paragraph/Paragraph";
import { StyledHeadline as Headline } from "../components/headline/Headline";
import { StyledLayoutSub as LayoutSub } from "../layouts/Sub"

// Context
import { SiteContext } from '../context/SiteContext';

export default function Home() {
    const siteContext = useContext(SiteContext);

    return <>
        <Helmet>
            <title>
                Datenschutz - Klickste UG
            </title>
        </Helmet>
        <LayoutSub
            theme={siteContext.theme}
            toggleTheme={siteContext.toggleTheme}
            changeNavigation={siteContext.changeNavigation}
            changeModal={siteContext.changeModal}
            hasLogo={true}
            activeNavigation={siteContext.activeNavigation}
            activeModal={siteContext.activeModal}>
            <Content>

                <Headline as={"h1"} none="extra-small" sm="small" fontWeight={600} hasSpacing={true}>Datenschutz</Headline>
                <Headline as="h2" sm="tiny">Haftung für Inhalte</Headline>
                <Paragraph>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei bekannt werden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</Paragraph>
                <Headline as="h2" sm="tiny">Haftung für Links</Headline>
                <Paragraph>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei bekannt werden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</Paragraph>
                <Headline as="h2" sm="tiny">Urheberrecht</Headline>
                <Paragraph>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.Die Betreiber der Seiten sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen.</Paragraph>
                <Headline as="h2" sm="tiny">Datenschutzerklärung</Headline>
                <Paragraph>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</Paragraph>
                <Paragraph>Klickste UG<br />
                    Graefestr. 51a<br />
                    10967 Berlin</Paragraph>
                <Paragraph>E-Mail: <a href="mailto:fragste@klickste.berlin">fragste@klickste.berlin</a></Paragraph>
                <Headline as="h2" sm="tiny">Ihre Betroffenenrechte</Headline>
                <Paragraph>Unter den oben angegebenen Kontaktdaten können Sie jederzeit folgende Rechte ausüben:</Paragraph>
                <ul>
                    <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
                    <li>Berichtigung unrichtiger personenbezogener Daten,</li>
                    <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
                    <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,</li>
                    <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
                    <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.</li>
                    <li>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</li>
                </ul>
                <Paragraph>Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a></Paragraph>
                <Headline as="h2" sm="tiny">Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</Headline>
                <Paragraph>Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</Paragraph>
                <ul>
                    <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
                    <li>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</li>
                    <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,</li>
                    <li>die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.</li>
                </ul>
                <Headline as="h2" sm="tiny">Erfassung allgemeiner Informationen beim Besuch unserer Website</Headline>
                <Paragraph>Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen.</Paragraph>
                <Paragraph>Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken verarbeitet:</Paragraph>
                <ul>
                    <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                    <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                    <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                    <li>zu weiteren administrativen Zwecken.</li>
                </ul>
                <Paragraph>Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.</Paragraph>
                <Paragraph>Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</Paragraph>
                <Headline as="h2" sm="tiny">Cookies</Headline>
                <Paragraph>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine Textdateien, die von einem Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre Verbindung zum Internet.</Paragraph>
                <Paragraph>Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.</Paragraph>
                <Paragraph>In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.</Paragraph>
                <Paragraph>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.</Paragraph>
                <Headline as="h2" sm="tiny">Verwendung von Scriptbibliotheken (Google Webfonts)</Headline>
                <Paragraph>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts <a href="https://www.google.com/webfonts/">(https://www.google.com/webfonts/).</a> Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.</Paragraph>
                <Paragraph>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten erheben.</Paragraph>
                <Paragraph>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></Paragraph>
                <Headline as="h2" sm="tiny">Änderung unserer Datenschutzbestimmungen</Headline>
                <Paragraph>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</Paragraph>
                <Headline as="h2" sm="tiny">Fragen an den Datenschutzbeauftragten</Headline>
                <Paragraph>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder oder kontaktieren Sie uns direkt über die oben genannten Kontaktdaten.</Paragraph>




            </Content>
        </LayoutSub >
    </>

}
